@font-face {
font-family: 'Charge Vector Black';
src: url('../public/Charge Vector Black.otf') format('truetype');
}
@font-face {
font-family: 'RetroTypo';

}
:root {
    --card-height: 700px;
    --card-width: calc(var(--card-height) / 1.5);
}


.me-auto.navbar-nav {
    gap: 30px;
    justify-content: center;
    width: 100%;
}
.me-auto.navbar-nav a{
text-decoration: none;
font-size: 20px;
}
iframe{
display: block;
margin: auto;
}
.center {
margin-top: 50px;
}
.loginButton i{
margin-right:25px;
}
.loginContainer p {
color: #255748;
font-family: Charge Vector Black;
font-size: 30px;
line-height: 33px;
text-align: center;
}
p.myNetworks {
color: #3b6887;
font-family: "Charge Vector Black";
font-size: 30px;
text-align: center;
}
.top3Desktop {
display: flex;
justify-content: center;
color: #4b4168;
}
.top3Desktop .item {
background: #fff;
border-radius: 0.5rem;
box-shadow: 0 0 4rem 0 rgb(0 0 0 / 10%), 0 1rem 2rem -1rem rgb(0 0 0 / 30%);
box-sizing: border-box;
cursor: pointer;
height: 17rem;
margin: 1rem 1rem 2rem;
padding: 2.8rem 0 0;
position: relative;
text-align: center;
-webkit-transform-origin: bottom;
transform-origin: bottom;
transition: -webkit-transform .2s ease-in-out;
transition: transform .2s ease-in-out;
transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
width: 13rem;
background: linear-gradient(45deg,#c6ec9b,#88e4b9);
}
.top3Desktop .item .pic {
position: absolute;
top: -2rem;
left: 4.2rem;
width: 4rem;
height: 4rem;
border-radius: 50%;
background-size: cover;
background-position: center;
margin-right: 1rem;
box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
}
.top3Desktop .item .pos {
font-weight: 900;
}
.top3Desktop .item .name {
font-size: 1.1rem;
margin-bottom: 0.5rem;
}
.top3Desktop .item .score:after {
display: block;
content: "pokemons";
}
.top3Desktop .item.one {
border: 4px solid gold;
height: 17rem;
padding-top: 3.5rem;
width: 15rem;
}
.top3Desktop .item.one .picOne {
background-image: url(https://pokemon.gameinfo.io/images/tools/badge_lv3.png);
height: 5rem;
left: 4.8rem;
width: 5rem;
background-color: gold;
border: solid gold 4px;
}
.top3Desktop .item:hover {
transform: scale(1.05);
}

.top3Mobile {
padding-left: 2rem;
margin: 0 auto;
}
.top3Mobile .itemOne {
align-items: center;
background: transparent;
border-radius: 4rem;
box-shadow: 0 0 4rem 0 rgb(0 0 0 / 10%), 0 1rem 2rem -1rem rgb(0 0 0 / 30%);
color: #255748;
cursor: pointer;
display: flex;
height: 3rem;
margin: 30px auto auto;
position: relative;
transform-origin: left;
transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
width: 40%;
font-family: 'Charge Vector Black';
border: solid gold 4px;
}
.top3Mobile .itemOne .pos {
font-weight: 900;
position: absolute;
left: -2rem;
text-align: center;
font-size: 1.25rem;
width: 1.5rem;
color: white;
opacity: 0.6;
transition: opacity 200ms ease-in-out;
}
.top3Mobile .itemOne .picOne {
align-items: self-end;
background-color: gold;
background-image: url(https://pokemon.gameinfo.io/images/tools/badge_lv3.png);
background-size: contain;
border-radius: 50%;
box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%), 0 1rem 1rem -0.5rem rgb(0 0 0 / 30%);
display: flex;
height: 4rem;
justify-content: center;
margin-right: 1rem;
width: 4rem;
left: -5px;
position: inherit;
}
.top3Mobile .itemOne .name {
flex-grow: 2;
flex-basis: 10rem;
font-size: 1.1rem;
}
.top3Mobile .itemOne .score {
margin-right: 1.5rem;
}
.top3Mobile .itemOne .score:after {
margin-right: 1rem;
content: " pokemons";
}
.top3Mobile .itemOne:hover {
transform: scale(1.05);
}
.top3Mobile .itemOne:hover .pos {
opacity: 0.8;
}

.top3Mobile .itemTwo {
align-items: center;
background: transparent;
border-radius: 4rem;
box-shadow: 0 0 4rem 0 rgb(0 0 0 / 10%), 0 1rem 2rem -1rem rgb(0 0 0 / 30%);
color: #255748;
cursor: pointer;
display: flex;
height: 3rem;
margin: 30px auto auto;
position: relative;
transform-origin: left;
transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
width: 40%;
font-family: 'Charge Vector Black';
border: solid silver 4px;
}
.top3Mobile .itemTwo .pos {
font-weight: 900;
position: absolute;
left: -2rem;
text-align: center;
font-size: 1.25rem;
width: 1.5rem;
color: white;
opacity: 0.6;
transition: opacity 200ms ease-in-out;
}
.top3Mobile .itemTwo .picTwo {
align-items: self-end;
background-color: silver;
background-image: url(https://pokemon.gameinfo.io/images/tools/badge_lv2.png);
background-size: contain;
border-radius: 50%;
box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%), 0 1rem 1rem -0.5rem rgb(0 0 0 / 30%);
display: flex;
height: 4rem;
justify-content: center;
margin-right: 1rem;
width: 4rem;
left: -5px;
position: inherit;
}
.top3Mobile .itemTwo .name {
flex-grow: 2;
flex-basis: 10rem;
font-size: 1.1rem;
}
.top3Mobile .itemTwo .score {
margin-right: 1.5rem;
}
.top3Mobile .itemTwo .score:after {
margin-right: 1rem;
content: " pokemons";
}
.top3Mobile .itemTwo:hover {
transform: scale(1.05);
}
.top3Mobile .itemTwo:hover .pos {
opacity: 0.8;
}
.top3Mobile .itemThree {
align-items: center;
background: transparent;
border-radius: 4rem;
box-shadow: 0 0 4rem 0 rgb(0 0 0 / 10%), 0 1rem 2rem -1rem rgb(0 0 0 / 30%);
color: #255748;
cursor: pointer;
display: flex;
height: 3rem;
margin: 30px auto auto;
position: relative;
transform-origin: left;
transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
width: 40%;
font-family: 'Charge Vector Black';
border : solid #F1AB70 4px;
}
.top3Mobile .itemThree .pos {
font-weight: 900;
position: absolute;
left: -2rem;
text-align: center;
font-size: 1.25rem;
width: 1.5rem;
color: white;
opacity: 0.6;
transition: opacity 200ms ease-in-out;
}
.top3Mobile .itemThree .picThree {
align-items: self-end;
background-color: sandybrown;
border : solid #F1AB70 4px;
background-image: url(https://pokemon.gameinfo.io/images/tools/badge_lv1.png);
background-size: contain;
border-radius: 50%;
box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%), 0 1rem 1rem -0.5rem rgb(0 0 0 / 30%);
display: flex;
height: 4rem;
justify-content: center;
margin-right: 1rem;
width: 4rem;
left: -5px;
position: inherit;
}
.top3Mobile .itemThree .name {
flex-grow: 2;
flex-basis: 10rem;
font-size: 1.1rem;
}
.top3Mobile .itemThree .score {
margin-right: 1.5rem;
}
.top3Mobile .itemThree .score:after {
margin-right: 1rem;
content: " pokemons";
}
.top3Mobile .itemThree:hover {
transform: scale(1.05);
}
.top3Mobile .itemThree:hover .pos {
opacity: 0.8;
}
.list {
margin: 0 auto;
}
.list .item {
align-items: center;
background: #3c6654;
border-radius: 4rem;
box-shadow: 0 0 4rem 0 rgb(0 0 0 / 10%), 0 1rem 2rem -1rem rgb(0 0 0 / 30%);
cursor: pointer;
display: flex;
height: 3rem;
position: relative;
-webkit-transform-origin: left;
transform-origin: left;
transition: -webkit-transform .2s ease-in-out;
transition: transform .2s ease-in-out;
transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
color: white;
width: 40%;
margin: auto;
margin-top: 30px;
}
.list .item .pos {
font-weight: 900;
position: absolute;
left: -2rem;
text-align: center;
font-size: 1.25rem;
width: 1.5rem;
color: white;
opacity: 0.6;
transition: opacity 200ms ease-in-out;
}
.list .item .pic {
background-position: 50%;
background-size: cover;
border-radius: 50%;
box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%), 0 1rem 1rem -0.5rem rgb(0 0 0 / 30%);
height: 4rem;
margin-right: 1rem;
width: 4rem;
background-color: #3C6537;
display: flex;
align-items: self-end;
justify-content: center;
}
.list .item .name {
flex-grow: 2;
flex-basis: 10rem;
font-size: 1.1rem;
}
.list .item .score {
margin-right: 1.5rem;
}
.list .item .score:after {
margin-right: 1rem;
content: " pokemons";
}
.list .item:hover {
transform: scale(1.05);
}
.list .item:hover .pos {
opacity: 0.8;
}
.one .pos {
font-size: 3.5rem;
}
.two .pos {
margin-top: 15px;
font-size: 3.0rem;
}
.three .pos {
margin-top: 15px;
font-size: 2.5rem;
}
.picTwo{
background-color: silver;
border: solid silver 4px;
background-image: url(https://pokemon.gameinfo.io/images/tools/badge_lv2.png);
}
.picThree{
background-color: sandybrown;
border : solid #F1AB70 4px;
background-image: url(https://pokemon.gameinfo.io/images/tools/badge_lv1.png);
}
.two{
border: solid silver 4px;
}
.three{
border : solid #F1AB70 4px;
}
.pic p{
font-weight: bold;
font-size: 22px;
text-align: center;
}

.buttonToPointContainer{
bottom: 20px;
display: flex;
justify-content: center;
position: fixed;
width: 100%;
flex-flow: column;
margin: auto;
text-align: center;
font-size: 20px;
}

.buttonToPoint{
background: #fff;
border-color: red;
border-radius: 50px;
border-width: 20px;
height: 100px;
width: 100px;
display: block;
font-size: 40px;
color: black;
margin: auto;
}

@keyframes floatArrow {
0% {
    transform: translatey(0px);
}
50% {
    transform: translatey(-20px);
}
100% {
    transform: translatey(0px);
}
}

@keyframes disableButton {
0% {
    opacity: 1;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 0;
    display: none;
}
}

.buttonArrow{
-webkit-animation: float 6s ease-in-out infinite;
animation: floatArrow 6s ease-in-out infinite;
-webkit-transform: translatey(0);
transform: translatey(0);
font-size: 45px;
font-weight: bolder;
}

.disableButtonPoint{
display: none;
animation: disableButton 1s ease-in-out forwards;
}
.textButtonPoint{
width: fit-content;
color: white;
background: rgba(129,0,0,0.8);
margin: auto;
padding: 10px;

}
.selectGen{
width: 200px;
margin: auto;
}

.pokemon {
position: relative;
height: 100px;
width: 100px;
background: linear-gradient(to bottom, rgb(254, 0, 1) 50%, white 50% );
border-radius: 50%;
border: 8px solid black;
animation: spinBall 1s linear infinite;
margin: auto;
}

.pokemon::before {
content: '';
position: absolute;
height: 8px;
width: 90px;
background: black;
top: 50px;
transform: translatey(-100%);
}

.pokemon::after {
content: '';
position: absolute;
height: 38px;
width: 38px;
border-radius: 50%;
background: white;
top: 50px;
left: 50px;
transform: translate(-71%, -71%);
box-shadow: inset 0 0 0 8px black, inset 0 0 0 10px white, inset 0 0 0 12px black;

}
.spinBox{
    animation:spinBall 1s infinite alternate ;
}
/* Spin Animation */
@keyframes spinBall {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}











img {
  display: block;
  margin: auto;
  width: 100px;
}
ul {
    display: flex;
    width: 50%;
    justify-content: space-around;
    margin: auto;
    list-style: none;
    padding:0;
}
li a {
    color: #255748;
    text-decoration: none;
    font-size: 30px;
}
li{
    color: #255748;
    text-decoration: none;
    font-size: 30px;
}
.filtersContainer {
    display: flex;
    flex-flow: column;
    width: 322px;
    align-items: center;
    position: absolute;
    top: 177px;
    left: 4%;
    gap: 10px;
}
button.filterButton {
    background: none;
    font-size: 20px;
    padding: 10px;
    width: 100px;
    border-radius: 20px;
    border:solid 4px #255748
}
button.filterButton:hover {
    background: #255748;
    font-size: 20px;
    padding: 10px;
    width: 100px;
    border-radius: 20px;
    color: white;
}
p.filterTitle {
    font-size: 35px;
}
.selected{
  font-weight: bold;
}


.labelStats {
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  font-family: 'Charge Vector Black';
  color: #255748;
  font-size: 30px;
}
.valueStats {
  background-color: #fff;
  border-radius: 88px;
  color: #255748;
  display: block;
  float: right;
  font-weight: 700;
  height: 30px;
  line-height: 13px;
  min-width: 88px;
  padding: 10px;
  text-align: center;
  font-size: 20px;
}
.infoPkm{
  position: absolute;
  top: 0;
}
.infoNbCapture{
  position: absolute;
  left: -19px;
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
.infoShiny{
    left: 90px;
    position: absolute;
    top: 105px;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
}
.stats {
    display: flex;
    gap: 30px;
    justify-content: center;
}
.uniquePokemonContainer {
    background-position: 50%;
    background-repeat: no-repeat;
    background: rgba(37,87,72,.2);
    border-radius: 25%;
    -webkit-filter: drop-shadow(0 0 12px #000);
    filter: drop-shadow(0 0 12px black);
    padding: 15px;
}

.inputPseudo{
  background-color: hsla(0,0%,100%,.9);
  border-radius: 25px;
  color: #255748;
  font-size: 40px;
  margin: 20px;
  text-align: center;
  width: 100%;
  border: black solid 4px;
}

.uploadPost{
  align-items: center;
    display: flex;
    justify-content: center;
}
.buttonPseudo{
  background-color: hsla(0,0%,100%,.9);
  border-radius: 25px;
  color: #255748;
  font-size: 25px;
  height: 50px;
  text-align: center;
  width: 50px;
  border: solid black 4px;
  position: absolute;
  right: 2%;
}
.top3Mobile{
  display: none;
}
img.imgPokemonPage {
    width: 200px;
}
p.numberPokemonPage {
    text-align: center;
    font-size: 50px;
    color: #255748;
    font-family: Charge Vector Black;
}
p.namePokemonPage {
    color: #255748;
    font-family: Charge Vector Black;
    font-size: 50px;
    text-align: center;
    line-height: 10px;
}
.pokemonTypeContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.pokemonTypeContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.pokemonTypeContainer img {
    margin: 0;
    margin-top: 10px;
}
.capturesContainer {
    color: #255748;
    font-family: Charge Vector Black;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 150px;
}
p.titleMyCaptures {
    font-size: 35px;
    margin-top: 20px;
}

.pokemonPageContainer {
    display: flex;
    justify-content: space-evenly;
}
.myCapturesContainer {
    text-align: center;
    color: #255748;
    font-family: Charge Vector Black;
}

.myCaptureItem {
  align-items: center;
  border-radius: 5px;
  color: #255748;
  display: flex;
  font-size: 20px;
  gap: 30px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  padding: 10px;
  background: rgba(37,87,95,.2);
}
p.myCapturesList {
    margin: 0;
}
p.shinyPokemonList{
  color:gold;
}
p.titleMyCaptures{
  color: #255748;
    font-family: Charge Vector Black;
    font-size: 20px;
    text-align: center;
}
.alreadyTraded{
  margin: 0;
  background: #bc3636;
  padding: 5px;
  border-radius: 25px;
  color: white;
  padding: 10px;
}
.createTradeButton {
  background: #0a3a2c;
  border-radius: 25px;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  text-decoration: none;
  border:none;
}
.createTradeButton:hover {
  background: white;
  border-radius: 25px;
  color: #0a3a2c;
  font-size: 15px;
  padding: 10px;
  text-decoration: none;
  border:none;
}
.myTradesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}
.pokemonNameTrade{
  text-align: center;
  color: #255748;
  font-family: Charge Vector Black;
  font-size: 15px;
  margin-top: 5px;
}
.uniqueTradeContainer {
    padding: 20px;
    background: rgba(37, 87, 95,0.2);
    border-radius: 25px;
    height: 390px ;
}
.uniqueMyCardContainer {
    background: rgba(37,87,95,.2);
    border-radius: 25px;
    padding: 20px;
}
button.deleteTrade {
    display: block;
    margin: auto;
    border: none;
    background: red;
    border-radius: 25px;
    padding: 8px;
    width: 100%;
    color: white;
    font-family: Charge Vector Black;
    letter-spacing: 2px;
}
.lastCardUsername {
    color: #fff;
    font-size: 90px;
    text-align: center;
    text-shadow: 2px 3px 10px #000;
    z-index: 1;
    position: relative;
    top: 80px;
}
.lastBangerContainerBounceOut{
    position: absolute;
    left: 580px;
    animation: bounceLastBangerOut 1s forwards;
}
.guessTradeButton {
    background: #0a3a2c;
    border-radius: 25px;
    color: #fff;
    font-size: 15px;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;
    margin-top: 10px;
    font-family: Charge Vector Black;
    width: 100%;
    letter-spacing: 2px;
    width: 236px;
    margin:auto;
}
.guessTradeButton:hover{
  background: white;
  color: #0a3a2c;
}
.tradeHub {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
    align-items: center;
}
img.imgPokemonTrade {
    width: 100%;
}
.guessedPokemon{
  width:300px;
}
.guessedPokemonName{
  color: #255748;
  font-family: Charge Vector Black;
  font-size: 30px;
  margin-top: 5px;
  text-align: center;
}
.autoSearchItem{
  font-size: 10px;
  display: block;
  text-align: center;
  margin: auto;
}
.autoSearchItemContainer{
  display: flex;
  flex-flow: column;
}
.autoCompleteSearch{
  display: block;
  margin:auto;
  width:300px;
    font-size: 25px;
    color: #255748;
    font-family: Charge Vector Black;
}
.autoCompleteSearch input{
  font-size: 25px;
  color: #255748;
  font-family: Charge Vector Black;
}
ul{
  flex-wrap: wrap;
  display: block;
  margin: auto;
}
li{
  font-size: 25px;
  width: 300px;
  color: #255748;
  font-family: Charge Vector Black;
}
li:hover{
  cursor: pointer;
  background-color: #eee;
}
li svg{
  display: none;
}
.allreadyGuessButton{
  background: red;
  border-radius: 25px;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  text-decoration: none;
  border:none;
  display: block;
  margin: auto;
  font-family: Charge Vector Black;

}
.validateGuessButton{
  background: #0a3a2c;
  border-radius: 25px;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  text-decoration: none;
  border:none;
  display: block;
  margin: auto;
  font-family: Charge Vector Black;
}
.validateGuessButton:hover{
  background: white;
  color: #0a3a2c;
}


span.myCountProposition {
    background-color: red;
    color: white;
    font-size: 10px;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 26px;
}
.myTradesLink{
  display: flex;
  gap: 5px;
  align-items: center;
}
.noTrade{
  text-align: center;
}


#backgroundGlobal {
  position: fixed;
  left: 0;
  right: 0;
  display: block;
  background-size:contain;

  width: 100vw;
  height: 100vh;
  -webkit-filter: opacity(0.8);
  -moz-filter: grayscale(0.8);
  -o-filter: grayscale(0.8);
  -ms-filter: grayscale(0.8);
  filter: grayscale()(0.8);
  z-index: -10000;
  background-color: rgba(0,0,0,0);
    top:0;
}
@import url("https://fonts.googleapis.com/css?family=Russo+One");
@import url('https://fonts.cdnfonts.com/css/common-pixel');
@keyframes float {
  0% {
    top: 0;
  }
  30% {
  transform: rotate(5deg);
    top: -5px;
  }
  50% {
  transform: rotate(-5deg);
    top: -5px;
  }75% {
  transform: rotate(5deg);
    top: -5px;
  }
  100% {
  transform: rotate(0deg);
    top: 0px;
  }
}
@keyframes shadow-aperture {
  0% {
    opacity: 0.35;
    transform: translate(-50%, -50%) scale(1, 0.2);
  }
  30% {
    opacity: 0.15;
    transform: translate(-50%, -50%) scale(0.8, 0.15);
  }
  90% {
    opacity: 0.4;
    transform: translate(-50%, -50%) scale(1.1, 0.25);
  }
  100% {
    opacity: 0.35;
    transform: translate(-50%, -50%) scale(1, 0.2);
  }
}
@keyframes inflate {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3600deg);
  }
}
* {
  box-sizing: border-box;
}
@keyframes horizontal-shaking {
 0% { transform: translateX(0) }
 25% { transform: translateX(5px) }
 50% { transform: translateX(-5px) }
 75% { transform: translateX(5px) }
 100% { transform: translateX(0) }
}
body, html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Russo One";
}

#stage {
    height: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.content-width {
  max-width: 760px;
  margin: 0 auto;
}
img.done {
    position: absolute;
    margin-left: 192px;
    margin-top: -27px;
}

#again {
  display: none;
}
.nbToBuy{
    display: block;
    margin: auto;
    margin-top: 10px;
    border: none;
    background: #90e5b5;
}
.boxContainer {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    gap: 44px;
    position: relative;
    top: 80px;
}
img.imgBox {
    width: 226px;
    animation: float 6s ease-in-out infinite;
}
.uniqueBoxContainer {
    background: none;
    border: none;
    position: relative;
}
p.nbBox {
    color: #fff;
    font-size: 90px;
    position: absolute;
    top: 86px;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 3px 10px black;
}
object{

  -webkit-filter: opacity(0.9);
  -moz-filter: grayscale(0.9);
  -o-filter: grayscale(0.9);
  -ms-filter: grayscale(0.9);
  filter: grayscale()(0.9);
}
.nbCardList{
  position: absolute;
    background: #0a3a2c;
    width: fit-content;
    height: 15px;
    color: white;
    text-align: center;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.allCards{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    flex-flow: column;
}
.fit-picture{
    width: 150px;
}
.fit-picture-card{
    width:250px ;
    margin: 0;
}
.backButton {
    background: none;
    border: none;
    font-size: 15px;
    margin-top: 10px;
    padding: 10px;
    text-decoration: none;
    width: fit-content;
    color: #0a3a2c;
}
.containerImgBooster {
    display: flex;
    height: 150px;
}
#twitch-chat-embed{
    position: fixed;
    right: 25px;
    top:65px;
}
#cardsContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 14px;
    margin: auto;
    justify-content: center;
}
.myPointsDisplay button{
    background: none;
    border: none;
}
.myPointsDisplay{
    display: flex;
    width: 100%;
    justify-content: center;
}
.dropCards{
    width: 350px;
    position: absolute;
}
.dropBooster{
    width: 350px;
    position: absolute;
}
.myCardsContainer {
    display: flex;
    flex-flow: column;
    align-items: end;
    justify-content: center;
    height: 0;
}
.getBox{
    animation:bounceOut 1s;
    animation-fill-mode: forwards;
}
.gettedBoxContainer {
    -webkit-animation: bounceIn 1s;
    animation: bounceIn 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    width: 50%;
    height: 85vh;
    overflow: hidden;
}
img.gettedBoxImg {
    bottom: 200px;
    position: absolute;
    right: 50px;
    width: 350px;
}
.gettedGameImg{
     -webkit-animation: showGame 2s;
    animation: showGame 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    position: relative;
    visibility: visible;
    width: 600px;
    z-index: 1;
    margin-left: 400px;
    bottom: -300px;
}
.textResultBoxRare {
    -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
    animation: neon1 1.5s ease-in-out infinite alternate;
    color: #fff;
    font-size: 65px;
    top: 450px;
    z-index: 1;
    position: absolute;
    width: 100vw;
    left: -35px;
}

@-webkit-keyframes neon1 {
    from {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px green, 0 0 35px green, 0 0 40px green, 0 0 50px green, 0 0 75px green;
    }
    to {
        color:green;
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px green, 0 0 70px green, 0 0 80px green, 0 0 100px green, 0 0 150px green;
    }
}

.textResultBoxEpic {
    -webkit-animation: neon2 1.5s ease-in-out infinite alternate;
    animation: neon2 1.5s ease-in-out infinite alternate;
    color: #fff;
    font-size: 65px;
    top: 450px;
    z-index: 1;
    position: absolute;
    width: 100vw;
    left: -35px;
}

@-webkit-keyframes neon2 {
    from {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px purple, 0 0 35px purple, 0 0 40px purple, 0 0 50px purple, 0 0 75px purple;
    }
    to {
        color:purple;
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px purple, 0 0 70px purple, 0 0 80px purple, 0 0 100px purple, 0 0 150px purple;
    }
}

.textResultBoxLegendary {
    -webkit-animation: neon3 1.5s ease-in-out infinite alternate;
    animation: neon3 1.5s ease-in-out infinite alternate;
    color: #fff;
    font-size: 65px;
    top: 450px;
    z-index: 1;
    position: absolute;
    width: 100vw;
    left: -35px;
}

@-webkit-keyframes neon3 {
    from {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px gold, 0 0 35px gold, 0 0 40px gold, 0 0 50px gold, 0 0 75px gold;
    }
    to {
        color:gold;
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px gold, 0 0 70px gold, 0 0 80px gold, 0 0 100px gold, 0 0 150px gold;
    }
}

.rainbow{
    font-family: 'Big Shoulders Stencil Text', cursive;
    font-size:128px;
    text-align: center;
}

.textResultBoxUltra {
    color: #fff;
    font-size: 65px;
    top: 450px;
    z-index: 1;
    position: absolute;
    width: 100vw;
    left: -35px;
    color:transparent;
    background: linear-gradient(270deg,#1e5799,#2ce0bf,#76dd2c,#dba62b,#ff1493,#1e5799);
    animation:neon4 5s cubic-bezier(0, 0.78, 0, 1.55) infinite alternate;
    background-size: 60% 100%;
    -webkit-background-clip:text;
}

@-webkit-keyframes neon4 {
    0%{
        filter: blur(100px);
        background-position-x:10240px;
        /*text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px gold, 0 0 70px gold, 0 0 80px gold, 0 0 100px gold, 0 0 150px gold;*/
    }
    to{
        text-shadow: 0 0 13px #fff;
        background-position-x:0;
    }
}

.textResultBoxBasic {
    -webkit-animation: neon5 1.5s ease-in-out infinite alternate;
    animation: neon5 1.5s ease-in-out infinite alternate;
    color: #fff;
    font-size: 65px;
    top: 450px;
    z-index: 1;
    position: absolute;
    width: 100vw;
    left: -35px;
}

@-webkit-keyframes neon5 {
    from {

        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px gray, 0 0 35px gray, 0 0 40px gray, 0 0 50px gray, 0 0 75px gray;
    }
    to {
        color:gray;
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px gray, 0 0 70px gray, 0 0 80px gray, 0 0 100px gray, 0 0 150px gray;
    }
}

.imgInBox{
    width: 600px;
}
@keyframes showGame {
    0% {
        transform: scale(.0001) translateX(-1500px) translateY(-1500px);
    }
    100% {
        transform: scale(1) translateX(-200px) translateY(-250px);
    }
}
@keyframes bounceLastBanger {
    0% {
        transform:scale(0);
    }
    5%{
        transform:scale(1)
    }
    95%{
        transform:scale(1);
    }
    100% {
        display: none;
        transform:scale(0);
    }
}
@keyframes bounceLastBangerAlert {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(1) rotate3d(0, 1, 0, 1080deg);

    }
    89% {
        transform: scale(1) rotate3d(0, 1, 0, 1080deg);
    }
    95% {
         transform: scale(1.2) rotate3d(0, 1, 0, 1080deg);
    }
    100%{
        transform: scale(0) rotate3d(0, 1, 0, 1080deg);
    }
}
@keyframes bounceLastBangerOut {
    0% {
        transform:scale(1);
    }
    100% {
        transform:scale(0);
    }
}
@keyframes bounceIn {
    0% {
        visibility: visible;
        transform:scale(0.0001);
    }
    60% {
        visibility: visible;
        transform:scale(1.1);
    }
    80% {
        visibility: visible;
        transform:scale(0.9);
    }
    100% {
        visibility: visible;
        transform:scale(1);
    }
}
@keyframes bounceOut {
    0% {
        transform:scale(1);
    }
    60% {
        transform:scale(1.1);
    }
    100% {
        visibility: hidden;
        transform:scale(0);
    }
}
@keyframes bounceInCards {
    0% {
        visibility: visible;
        transform:scale(0.0001);
    }
    60% {
        visibility: visible;
        transform:scale(1.1);
    }
    100% {
        visibility: visible;
        transform:scale(1);
    }
}
@keyframes moveCard {
    0% {
        transform: rotate(0deg);
        z-index:1;
    }
    25% {
        transform: translate(25em);
    }
    50% {
         transform: translate(25em);
        z-index:-2;
    }
    100% {
        transform: translate(0em);
        z-index:-2;
        visibility: hidden;
    }
}
.discoveredCardsContainer{
    display: contents;
}
.showBooster{
    visibility: hidden;
    animation:bounceIn 1s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
}
.hiddenBooster{
    animation:bounceOut 1s;
    animation-fill-mode: forwards;
}
.hiddenCards{
    visibility: hidden;
}
.showCards{
    visibility: hidden;
    animation:bounceInCards 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.6s;
}
.gettedCard{
    animation: moveCard 0.5s forwards;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    visibility: visible;
    z-index: -2;
}
.lastCard{
    z-index: 1;
}
.endPull{
    animation:bounceOut 1s;
    animation-fill-mode: forwards;
}
#shadowBox {
   height: 600px;
    width:350px;
}
.nbToSell{
    margin : 0;
}

.buttonToSellContainer {
    display: flex;
    position: fixed;
    bottom: 15px;
    z-index: 2;
}
button.buttonToSell {
    color: #0a3a2c;
    background: #90e5b5;
    font-size: 25px;
    border-radius: 25px;
    padding: 10px;
}
button.buttonToSell:hover {
    color: white;
    background: #0a3a2c;
}
.unsellButton{
    display: none;
}
.unsellButton {
    background: red;
    border: none;
    border-radius: 30px;
    color: #fff;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 40px;
    margin: -20px;
    overflow: hidden;
    padding-left: 11px;
    position: absolute;
    width: 40px;
    z-index: 1;
    margin-left: 220px;
    margin-top: -11px;
}
.nbToSellContainer {
    position: absolute;
    margin-left: 1px;
    color: white;
    background: #0a3a2c;
    padding: 5px;
    font-size: 13px;
    margin-top: 314px;
    z-index: 1;
}
.rainbow {
    text-align: center;
    text-decoration: underline;
    font-size: 32px;
    font-family: monospace;
    letter-spacing: 5px;
}
.rainbow_text_animated {
    -webkit-animation: rainbow_animation 6s ease-in-out infinite;
    animation: rainbow_animation 2s ease-in-out infinite;
    background: linear-gradient(90deg,#66f,#09f,#0f0,#f39,#66f);
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 400% 100%;
    color: transparent;
    font-size: 50px;
    text-align: left;
    font-weight: bold;
    filter: drop-shadow(2px 4px 6px black);
}

@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

@keyframes animate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}

.bigImageRainbow {
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageRainbow:before {
    animate: 0.5s;
    transition: 0.5s;
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;

}
.spanOnair{
    font-size: 10px;
}
a.linkOnAir {
    border-radius: 50px;
    color: #fff;
    display: flex;
    font-size: 38px;
    margin: auto auto 10px;
    text-align: center;
    text-decoration: none;
    width: 300px;
    flex-flow: column;
    padding: 10px;
    background: red;
}
a.linkOnAirOff {
    border-radius: 50px;
    color: #fff;
    display: flex;
    font-size: 38px;
    margin: auto auto 10px;
    text-align: center;
    text-decoration: none;
    width: 300px;
    flex-flow: column;
    padding: 10px;
    background: gray;
}
.countdown {
    width: 100%;
    display: flex;
    justify-content: center;
}

.searchPlayer{
    width: 300px;
    margin: auto;
}
.buttonShowList{
    background: #90e5b5;
    color: #255748;
}
.introTCGtext{
    text-align: center;
    margin: 20px;
}
.introTCGLink{
    color: #255748;
    font-family: Charge Vector Black;
    font-size: 25px;
}
.validationSellContainer {
    display: flex;
    justify-content: space-around;
}
.cancelSellButton{
    background: red;
    color: white;
    border: none;
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}
.validationSellButton{
    background: #0a3a2c;
    color: white;
    border: none;
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}
.cancelSellButton:hover{
    border: black solid 1px;
}
.validationSellButton:hover{
    border: black solid 1px;
}
.listUserElement {
    background-color: #255748;
    color: white;
    display: flex;
    border-radius: 15px;
    padding: 15px;
    line-height: 27px;
    justify-content:space-between;
}
.nbSellPickContainer{
    position: absolute;
    font-size: 80px;
    text-align: center;
    width: 250px;
    margin-top: 80px;
    opacity: 0.8;
    text-shadow: 0px 0px 10px aqua;
}

.nbSellPick{
    margin: 0;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 20;
}
.listUser{
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 300px;
    margin: auto;
}

.exitModalButton {
    position: absolute;
    top: 65px;
    font-size: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: rgba(0,0,0,0.8);
    color: white;
    z-index: 1;
}

.bigImageRainbow:before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageGold:hover {
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageGold:before {
    animate: 0.5s;
    transition: 0.5s;
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg,#FFDD00,#fff,#FFDD00);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;

}

.bigImageGold:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageBase:hover {
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageBase:before {
    animate: 0.5s;
    transition: 0.5s;
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg,green,#fff,green);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;

}

.bigImageBase:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageRare:hover {
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.bigImageRare:before {
    animate: 0.5s;
    transition: 0.5s;
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg,#0AE4EB,#fff,#0AE4EB);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;

}

.bigImageRare:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}
.glowGet{
    display: block;
    animation: bounceInCards 1s forwards;
}

.glowGetGreen{
    display: block;
    filter: drop-shadow(2px 4px 30px green);
    animation: glowGetGreen 2s infinite alternate, bounceInCards 1s forwards;
}

.glowGetBlue{
    display: block;
    filter: drop-shadow(2px 4px 30px blue);
    animation: glowGetBlue 2s infinite alternate, bounceInCards 1s forwards;
}
.glowGetGold{
    display: block;
    filter: drop-shadow(2px 4px 30px blue);
    animation: glowGetGold 2s infinite alternate, bounceInCards 1s forwards;
}
.glowGetRainbow{
    display: block;
    filter: drop-shadow(2px 4px 30px blue);
    animation: glowGetRainbow 2s infinite alternate, bounceInCards 1s forwards;
}

.cardOnListRainbow{
    animation : glowGetRainbow 2s infinite alternate;
}

@keyframes glowGetGreen {
    0% {
        filter: drop-shadow(2px 4px 5px green) drop-shadow(2px 4px 5px #0000) drop-shadow(2px 4px 5px green);
    }

    100% {
        filter: drop-shadow(2px 4px 15px green) drop-shadow(2px 4px 15px #0000) drop-shadow(2px 4px 15px green);
    }
}

@keyframes glowGetBlue {
    0% {
        filter: drop-shadow(2px 4px 5px #0AE4EB) drop-shadow(2px 4px 5px #0000) drop-shadow(2px 4px 5px #0AE4EB);
    }

    100% {
        filter: drop-shadow(2px 4px 15px #0AE4EB) drop-shadow(2px 4px 15px #0000) drop-shadow(2px 4px 15px #0AE4EB);
    }
}

@keyframes glowGetGold {
    0% {
        filter: drop-shadow(2px 4px 5px #FFDD00) drop-shadow(2px 4px 5px #fff) drop-shadow(2px 4px 5px #FFDD00);
    }

    100% {
        filter: drop-shadow(2px 4px 15px #FFDD00) drop-shadow(2px 4px 15px #fff) drop-shadow(2px 4px 15px #FFDD00);
    }
}

@keyframes glowGetRainbow {
    0% {
        -webkit-filter: drop-shadow(2px 4px 15px red);
        filter: drop-shadow(2px 4px 35px #ff0000);
    }

    10% {
        -webkit-filter: drop-shadow(2px 4px 15px orange);
        filter: drop-shadow(2px 4px 35px #ffa500);
    }
    20% {
        -webkit-filter: drop-shadow(2px 4px 15px #ff0);
        filter: drop-shadow(2px 4px 35px #ffff00);
    }
    30% {
        -webkit-filter: drop-shadow(2px 4px 15px green);
        filter: drop-shadow(2px 4px 35px #008000);
    }
    40% {
        -webkit-filter: drop-shadow(2px 4px 15px #00f);
        filter: drop-shadow(2px 4px 35px purple);
    }
    50% {
        -webkit-filter: drop-shadow(2px 4px 15px indigo);
        filter: drop-shadow(2px 4px 35px pink);
    }
    60% {
        -webkit-filter: drop-shadow(2px 4px 15px violet);
        filter: drop-shadow(2px 4px 35px yellow);
    }
    70% {
        -webkit-filter: drop-shadow(2px 4px 15px red);
        filter: drop-shadow(2px 4px 35px gold);
    }
    80% {
        -webkit-filter: drop-shadow(2px 4px 15px orange);
        filter: drop-shadow(2px 4px 35px darkorange);
    }
    90% {
        -webkit-filter: drop-shadow(2px 4px 15px #ff0);
        filter: drop-shadow(2px 4px 35px red);
    }
    100% {
        -webkit-filter: drop-shadow(2px 4px 15px #0ae4eb);
        filter: drop-shadow(2px 4px 35px #0AE4EB);
    }
}
.leaderBoardSwitchMobile{
    display: none;
}
button.navbar-toggler {
    display: block;
    margin: auto;
}
.linkFromNav{
    text-align: center;
}
span.navbar-brand {
    position: absolute;
    top: 0;
}

.twitch .twitch-video {
    padding-top: 56.25%;
    position: relative;
    height: 0;
}

.twitch .twitch-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.twitch .twitch-chat {
    height: 400px;
}

.twitch .twitch-chat iframe {
    width: 100%;
    height: 100%;
}
@media screen and (min-width: 944px) and (max-width: 1659px) {
  .clipsGlobalContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: auto;
    width: 88%;
  }
}
@media screen and (min-width: 380px) and (max-width: 943px) {
    .leaderBoardSwitchMobile{
        display: flex;
    }
    .selectPage{
        margin: auto;
        width: fit-content;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 25px;
        color: #255748;
        font-family: Charge Vector Black;
        text-align: center;
    }
    .leaderBoardSwitch{
        display: None;
    }
  .pokemonGlobalContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: auto;
    width: 100%;
  }
    .top3Mobile{
      display: block;
      padding:0;
    }
    .top3Desktop{
      display: none;
    }
    .top3Mobile .itemOne{
      width: 380px;
    }
    .top3Mobile .itemTwo{
      width: 375px;
    }
    .top3Mobile .itemThree{
      width: 370px;
    }
    .list .item{
      width: 370px;
    }
    .list{
      padding:0;
    }
    .top3Mobile .itemOne .name {
      flex-grow: 0;
    }
    .top3Mobile .itemTwo .name {
      flex-grow: 0;
    }
    .top3Mobile .itemThree .name {
      flex-grow: 0;
    }
    .list .item .name {
      flex-grow: 0;
    }

    .capturesContainer{
      display: block;
      margin: auto;
      width: 300px;
      color: #255748;
      font-family: Charge Vector Black;
      text-align: center;
    }
}
@media screen and (max-width: 379px) {
  .pokemonGlobalContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: auto;
    width: 100%;
  }
    .top3Mobile{
      display: block;
      padding:0;
    }
    .top3Desktop{
      display: none;
    }
    .top3Mobile .itemOne{
      width: 320px;
    }
    .top3Mobile .itemTwo{
      width: 320px;
    }
    .top3Mobile .itemThree{
      width: 320px;
    }
    .list .item{
      width: 320px;
    }
    .list{
      padding:0;
    }
    .top3Mobile .itemOne .name {
      flex-grow: 0;
    }
    .top3Mobile .itemTwo .name {
      flex-grow: 0;
    }
    .top3Mobile .itemThree .name {
      flex-grow: 0;
    }
    .list .item .name {
      flex-grow: 0;
    }
    .top3Mobile .itemOne .score:after {
      margin-right: 1rem;
      content: "";
    }
    .top3Mobile .itemTwo .score:after {
      margin-right: 1rem;
      content: "";
    }
    .top3Mobile .itemThree .score:after {
      margin-right: 1rem;
      content: "";
    }
    .list .item .score:after {
      margin-right: 1rem;
      content: "";
    }
    .top3Mobile .itemOne .score {
      right: 10px;
      position: absolute;
    }
    .top3Mobile .itemTwo .score {
      right: 10px;
      position: absolute;
    }
    .top3Mobile .itemThree .score {
      right: 10px;
      position: absolute;
    }
    .list .item .score {
      right: 10px;
      position: absolute;
    }

    .capturesContainer{
      display: block;
      margin: auto;
      width: 300px;
      color: #255748;
      font-family: Charge Vector Black;
      text-align: center;
    }
}
.list .myItem{
  background: #700d0f;
}
.list .myItem .pic{
  background: #570a0c;
}
.uniqueBox:hover{
  background: transparent;
}
#modal-container {
    position: fixed;
    display: table;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: scale(0);
    z-index: 1;
}
#modal-container.one {
    transform: scaleY(0.01) scaleX(0);
    animation: unfoldIn 0.5s forwards;
    animation-delay: 0s;
}
#modal-container.one .modal-background .modal {
    transform: scale(0);
    animation: zoomIn 3s 10s forwards;
    animation-delay: 0.8s;
}
#modal-container.one.out {
    transform: scale(1);
    animation: unfoldOut 0.5s forwards;
}
#modal-container.one.out .modal-background .modal {
    animation: zoomOut 0.3s forwards;
}
#modal-container .modal-background {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
#modal-container .modal-background .modal {
    background: white;
    padding: 50px;
    display: inline-block;
    border-radius: 3px;
    font-weight: 300;
    position: relative;
    background: none;
}
#modal-container .modal-background .modal h2 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 15px;
}
#modal-container .modal-background .modal p {
    font-size: 18px;
    line-height: 195px;
}
#modal-container .modal-background .modal .modal-svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border:none;
    background: none;
}
#modal-container .modal-background .modal .modal-svg rect {
    stroke: #fff;
    stroke-width: 2px;
    stroke-dasharray: 778;
    stroke-dashoffset: 778;
}

.content {
    min-height: 100%;
    height: 100%;
    background: white;
    position: relative;
    z-index: 0;
}

.buttonToDisplayStream {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttonToDisplayStream button{
    border: solid 2px black;
    background: none;
    border-radius: 25px;
    padding: 5px;
}
.buttonToDisplayStream button:hover{
    background-color: grey;
    border:none;
    color:white;
}

#root{
    background-image: linear-gradient(0deg, #38617f 20%, #9AD4DF 238%, #38617f 60%);
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

a{
    font-size: 50px;
    color: white;
    font-family: "Charge Vector Black";
}
a:hover{
    color: #cbcbcb;
}
.containerStream {
    display: flex;
    width: 100%;
    flex-flow: row;
    height: fit-content;
}
nav.navbar.navbar-expand-lg.navbar-light {
    background-color: #38617F!important;
    border-bottom: #325269 solid 2px;
}
.uniqueStreamer{
    background-color:#38617F;
}
.uniqueStreamer button{
    background-color:transparent;
    border: none;
}
.uniqueStreamer:hover{
    cursor: pointer;
    background-color:#5b87a7;
}
.streamersList{
    min-width: 300px;
    height: -webkit-calc(100vh - 55px);
    overflow: overlay;
    scrollbar-color: white transparent;
    scrollbar-width: thin;
}
.streamTitle{
    margin-top: 20px;
    text-align: center;
    color: white;
    font-size: 26px;
}
.uniqueStreamerOnline{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 90%;
    height: 90px;
    position: relative;
}
.uniqueStreamerProfile {
    display: flex;
    align-items: center;
    gap: 10px;
}
.uniqueStreamerProfile p{
    margin: 0;
    color:white;
}
.uniqueStreamerProfile img{
    width: 50px;
    border-radius: 50px;
}
.uniqueStreamerStats {
    display: flex;
    align-items: center;
    gap: 10px;
}
.uniqueStreamerStats img{
    width: 15px;
    border-radius: 50px;
}
.uniqueStreamerStats p{
    margin: 0;
    color:white;
}
.buttonToDisplayStream{
    height: 90px;
    position: absolute;
    left: -25px;
    width: 300px;
}

.homeContainer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.firstSectionHome {
    display: flex;
    justify-content: center;
    height: 100vh;
}
.firstSectionHome .homeContent {
    width: 70%;
    display: block;
    margin: auto;
    text-align: justify;
}
.secondSectionHome {
    background-image: linear-gradient(180deg, #37627f 24%, #7ea8c5 106%);
    border-top: 5px solid #f7bb3e;
    border-bottom: 5px solid #f7bb3e;
    display: flex;
    height: 85vh;
    justify-content: center;
    width: 100%;
    z-index: 2;
}
.infosAssoLink {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
}
.infosAssoLink a.socialLink {
    font-size: 20px;
    border-radius: 15px;
}
.infosAssoLink a.socialLink:hover {
    background-image: linear-gradient(180deg, #f7bb3e 24%, #fff 155%);
    color: white;
}
.linkContainerHome {
    width: 65%;
    display: block;
    margin: auto;
    margin-top: -75px;
}
.linkContainerHome p {
    color: #fff;
    font-size: 25px;
    text-align: justify;
}

.thirdFakeContent {
    width: 100%;
    height: 50vh;
    background-image: url("../public/images/animatedSOFK.gif");
    background-position: 50%;
    background-size: 50%;
    height: 50vh;
    width: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-color: #7ea8c5;
    background-attachment: fixed;
}
.thirdSectionHome {
    border-top: 5px solid #f7bb3e;
    border-bottom: 5px solid #f7bb3e;
    background-image: linear-gradient(0deg, #37627f 24%, #7ea8c5 106%);
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
    justify-content: center;
    width: 100%;
    z-index: 1;
    padding: 50px;
}
.thirdSectionHome .homeContent{
    width: 65%;
}
.fourthSectionHome {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #446f8a;
    z-index: 1;
    flex-flow: column;
    padding: 50px;
}
.paragraphHome{
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
}
.paragraphHome span{
    color: #fcc249 ;
    font-weight: bold;
}

.clipsGlobalContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    width: 75%;
    gap: 20px;
}
.infoClipContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 30px;
}
a.clipsLink {
    text-decoration: none;
    border-radius: 10px;
}
a.clipsLink:hover{
    background-color: white;
    color:#38617f;
    p.viewsClips {
        border: 1px #38617f solid;
    }
    p.dateClip {
        border: 1px #38617f solid;
    }
}
p.titleClip {
    font-size: 20px;
    text-align: center;
}
img.imgClip {
    width: 340px;
    border-radius: 10px 10px 0 0;
}
p.viewsClips {
    font-size: 15px;
    border: 1px white solid;
    padding: 6px;
}
p.dateClip {
    font-size: 15px;
    border: 1px white solid;
    padding: 6px;
}
ul.paginateLay {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
    flex-wrap: wrap;
    width: 99vw;
}
.paginateLay li{
    color: white;
    margin: 15px;
    width: auto;
}
.paginateLay li a{
    color: white;
    margin: 15px;
}
.paginateLay li.selected{
    background-color: white;
    color:#38617f;
    border-radius: 10px;
}
.paginateLay li:hover{
    background-color: white;
    color:#38617f;
    border-radius: 10px;
}
.paginateLay li a:hover{
    color:#38617f;
}
h1.titleEdition {
    color: #fff;
    font-family: Charge Vector Black;
    text-align: center;
}
.editionsInfosContainer {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
}
.editionsContainer {
     width: 303px;
     border: solid 2px white;
     padding: 30px;
     border-radius: 10px;
     display: flex;
     flex-flow: column;
 }
p.nbEdition {
    color: white;
    font-size: 30px;
    text-align: center;
}
p.totalEdition {
    font-size: 50px;
    text-align: center;
    color: #fcc249;
}

.loginButton{
    display: flex;
    background-color: #9AD4DE;
    color: #36617F;
    border: none;
    padding: 15px;
    border-radius: 30px;
    line-height: 22px;
    font-size: 25px;
}
.loginButton:hover {
    filter: drop-shadow(0px 0px 6px white);
}
button.disableStreamerListButton {
    background: none;
    color: white;
    right: 0;
    border: none;
    text-align: end;
    width: 100%;
    background-color: #38617f;
}
.streamersListMobile{
    display: none;
}
.disableStream {
    display: none;

}
.streamerMozaique{
    display: flex;
    flex-wrap: wrap;
    overflow: overlay;
    justify-content: center;
    gap: 20px;
    margin: 20px;
    scrollbar-color: white transparent;
    scrollbar-width: thin;
    height: 89vh;
}
.uniqueStreamerMozaique {
    width: 496px;
    height: 279px;
    position: relative;
    filter: drop-shadow(2px 4px 6px black);
}
button.buttonToDisplayStreamMozaique {
    width: 100%;
    height: 279px;
    border: none;
    background: rgba(0, 0, 0, 0.5);
}
.uniqueStreamerProfileMozaique {
    align-items: center;
    display: flex;
    gap: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
}
.uniqueStreamerProfileMozaique p{
    margin: 0;
    color:white;
}
.uniqueStreamerProfileMozaique img{
    width: 50px;
    border-radius: 50px;
}
.uniqueStreamerStatsMozaique {
    align-items: center;
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.uniqueStreamerStatsMozaique img{
    width: 15px;
    border-radius: 50px;
}
.uniqueStreamerStatsMozaique p{
    margin: 0;
    color:white;
}
.uniqueStreamerOnlineMozaique {
    height: inherit;
}
.uniqueStreamerOnlineMozaique:hover{
    filter: inherit;
}
.streamersListClips{
    min-width: 300px;
    height: -webkit-calc(100vh - 55px);
    overflow: overlay;
    scrollbar-color: white transparent;
    scrollbar-width: thin;
    position: fixed;

}
.showStreamerListButton{
    color: #38617f;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 75px;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    position: fixed;
    bottom: 20px;
    left: 20px;
}
.showStreamerListButton:hover{
    cursor: pointer;
}
.streamListHome {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    width: 50%;
    margin: auto;
}
.streamHomeContainer{
    display: flex;
    flex-flow: column;
    gap: 10px;
    width: 100px;

}
.streamHomeContainer p{
    color: white;
    text-align: center;
}
.streamHomeContainer a {
    border-radius: 10px;
    -webkit-filter: drop-shadow(2px 4px 6px #000);
    filter: drop-shadow(2px 4px 6px black);
    border: solid 2px #38617f;
}
.streamHomeContainer a img {
    padding: 10px;
    border-radius: 10px;
}
.streamHomeContainer a :hover{
    filter: drop-shadow(2px 4px 6px black);
    background-color: #38617F;
}
.footerContainer {
    width: 100%;
    background-color: #5B8AA1;
    height: 50px;
}
.footerContainer p {
    color: white;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
}
.consent-banner {
    display: none;
}
.linkToTwitch{
    background: purple;
    border-radius: 35px;
    text-align: center;
    text-decoration: none;
    width: 320px;
    border: 4px solid #f7bb3e;
  }
.donationLinkMobile{display: none}
a.liveButtonHome {
    display: block;
    margin: auto;
    border: 4px solid #f7bb3e;
    text-align: center;
    text-decoration: none;
    border-radius: 15px;
    width: 100%;
}
a.liveButtonHome:hover {
    background-image: linear-gradient(180deg, #f7bb3e 24%, #fff 155%);
    color: #fff;
}
a.socialLink {
    border: 4px solid #F7BB3E;
    border-radius: 35px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    width: 320px;
    font-size: 35px;
}

.partnerContainer {
    background-color: #f7bb3e;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    padding: 20px;
    position: relative;
    z-index: 10;
    padding: 10px;
}
.partnerContainer a{
    display: flex;
}
@media screen and (min-width: 850px) {
    .firstSectionHome .homeContent {
        position: fixed;
        top: 10%;
    }
    .secondSectionHome .homeContent img{
        position: relative;
        top: -20%;
        border: solid 5px #f7bb3e;
        border-radius: 50px;
    }
    .twitch {
        position: relative;
        margin-bottom: 0;
        width:100%;
    }

    .twitch .twitch-video {
        padding-top: 42.95%;
        width: 80%;
    }

    .twitch .twitch-chat {
        bottom: 0;
        height: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 20%;
    }
}
@media screen and (max-width: 849px) {
    .donationLinkMobile {
        position: absolute;
        margin: auto;
        top: 3px;
        z-index: 10000;
        width: 150px;
        font-size: 15px;
        bottom: auto;
        right: 0;
        display: block;
    }
    .firstSectionHome {
        padding-top:5%;
        height: fit-content;
        padding: 15px;
    }
    .firstSectionHome .homeContent {
        display: block;
        margin: auto;
        width: 90%;
        text-align: justify;
    }
    .infosAssoLink {
        gap: 15px;
        margin-top: 30px;
        flex-wrap: wrap;
    }
    .secondSectionHome {
        display: block;
        height: fit-content;
        padding: 15px;
    }
    .linkContainerHome {
        display: block;
        margin: auto;
        width: 90%;
    }
    .linkContainerHome p {
        color: #fff;
        font-size: 20px;
        text-align: justify;
    }
    .infosAssoLink a.socialLink {
        border-radius: 15px;
        font-size: 15px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .thirdFakeContent {
        background-repeat: no-repeat;
        width: 100%;
        z-index: 1;
        height: 20vh;
        background-size: 100%;
    }
    .thirdSectionHome .homeContent {
        width: 95%;
    }
    a.liveButtonHome {
        border-radius: 15px;
        display: block;
        margin: auto;
        width: 100%;
        font-size: 25px;
    }
    .streamListHome {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;
    }
    .streamersListClips{
        width: 100%;

    }
    .paginateLay li{
        color: white;
        margin: -5px;
        width: auto;
    }
    .streamerMozaique{
        display: none;
    }
        .disableStream {
            border: none;
            border-radius: 75px;
            color: #38617f;
            display: block;
            font-size: 20px;
            height: 30px;
            position: absolute;
            right: 20px;
            top: 80px;
            width: 30px;
            z-index: 10;
        }
    .streamersListMobile{display: block}
    .logoSofkOnStreamPage{
        display: none;
    }
    .donationLink{
        display: none;
    }
    .containerStream {
        display: block;
        flex-flow: row;
        height: fit-content;
        width: 100%;
    }
    .streamersList{
        display: none;
    }
    .twitch {
        margin-bottom: 0;
        position: relative;
        width: 100%;
    }

    .twitch .twitch-video {
        padding-top: 30vh;
        width: 100%;
    }

    .twitch .twitch-chat {
        bottom: 0;
        height: 100vh;
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
    }
}